import React from 'react'

export default function Page404 () {
  return (
    <div className="flex flex-col items-center mt-24">
      <svg className="hidden w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
      </svg>
      <h1 className="hidden text-6xl font-semibold text-gray-700 dark:text-gray-200">404</h1>
      <p className="text-gray-700 dark:text-gray-300 p-6 text-xl text-center">
        <a className="text-purple-600 hover:underline dark:text-purple-300" href="/">Page not found. <br /> Please check the address or go back.</a>
      </p>
    </div>
  )
}
