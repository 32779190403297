import React, { useContext, useEffect, useState } from 'react';
import { useAccount, useConnect } from 'wagmi'
import { ethers } from 'ethers'
import { truncateEthAddress } from '../lib/utils'
import { PriceContext } from '../context/PriceContext'

const WalletInfo = () => {
  const [balances, setBalances] = useState({
    bbcBalance: null,
    bbcReflections: null,

    memeBalance: null,
    memeReflections: null,

    rcoonBalance: null,

    degenWifBalance: null,
    degenWifReflections: null,

    dollarValue: null
  });

  const { address, isConnected } = useAccount();
  const { connect, connectors } = useConnect({
    onError(e) {
      console.log(e)
    },
  })
  const connector = connectors[0];

  const balance_fractions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  const reward_fractions = { minimumFractionDigits: 6, maximumFractionDigits: 6 }

  const bbc_abi = require('../assets/bbc.abi.json')
  const rewards_abi = require('../assets/dividend.token.abi.json')

  const bbc_address = process.env.REACT_APP_BBC_ADDRESS
  const meme_address = process.env.REACT_APP_MEME_ADDRESS
  const rcoon_address = process.env.REACT_APP_RCOON_ADDRESS
  const degenwif_address = process.env.REACT_APP_DEGENWIF_ADDRESS

  const bbc_rewards_address = process.env.REACT_APP_BBC_REWARDS
  const meme_rewards_address = process.env.REACT_APP_MEME_REWARDS
  const degenwif_rewards_address = process.env.REACT_APP_DEGENWIF_REWARDS

  useEffect(() => {
    async function fetchWalletData () {
      if (isConnected && address) {
        try {
          const provider = new ethers.BrowserProvider(ethereum)

          const bbc = new ethers.Contract(bbc_address, bbc_abi, provider)
          const meme = new ethers.Contract(meme_address, bbc_abi, provider)
          const rcoon = new ethers.Contract(rcoon_address, bbc_abi, provider)
          const degenwif = new ethers.Contract(degenwif_address, bbc_abi, provider)

          const bbc_reward = new ethers.Contract(bbc_rewards_address, rewards_abi, provider)
          const meme_reward = new ethers.Contract(meme_rewards_address, rewards_abi, provider)
          const degenwif_reward = new ethers.Contract(degenwif_rewards_address, rewards_abi, provider)

          const bbcBal = await bbc.balanceOf(address)
          const memeBal = await meme.balanceOf(address)
          const rcoonBal = await rcoon.balanceOf(address)
          const degenwifBal = await degenwif.balanceOf(address)

          const bbcRew = await bbc_reward.withdrawnDividendOf(address)
          const memeRew = await meme_reward.withdrawnDividendOf(address)
          const degenwifRew = await degenwif_reward.withdrawnDividendOf(address)

          setBalances({
            bbcBalance: ethers.formatEther(bbcBal),
            bbcReflections: ethers.formatEther(bbcRew),
            memeBalance: ethers.formatEther(memeBal),
            memeReflections: ethers.formatEther(memeRew),
            rcoonBalance: ethers.formatEther(rcoonBal),
            degenWifBalance: ethers.formatEther(degenwifBal),
            degenWifReflections: ethers.formatEther(degenwifRew),
          });
        } catch (e) {
          console.log(e)
        }
      }
    }

    fetchWalletData();
  }, []);



  return (
    <article className="w-full mx-auto hover:animate-background rounded-xl bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 p-0.5 shadow-xl transition hover:bg-[length:400%_400%] hover:shadow-sm hover:[animation-duration:_4s]">
      <div className="h-full rounded-[10px] bg-white p-4 sm:p-6">

        {!isConnected ? (
          <>
            <h3 className="mt-0.5 text-lg font-medium text-gray-900">
              Connect Wallet
            </h3>
            <a onClick={() => connect({ connector })} className="cursor-pointer inline-block rounded bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center">
              <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">Connect Wallet</span>
            </a>
          </>
        ) : (
          <>
            <h4 className="mb-4 font-semibold text-gray-600 dark:text-gray-300">
              Critter Rewards
            </h4>

            <p>BBC Balance: <span className="ml-2 text-pink-600">{Number(balances.bbcBalance).toLocaleString('en-us', balance_fractions)}</span></p>
            <p>BBC Rewards: <span className="ml-2 text-pink-600">{Number(balances.bbcReflections).toLocaleString('en-us', reward_fractions)} ETH</span></p>

            <hr className="my-4 border-purple-600" />

            <p>MEME Balance: <span className="ml-2 text-pink-600">{Number(balances.memeBalance).toLocaleString('en-us', balance_fractions)}</span></p>
            <p>MEME Rewards: <span className="ml-2 text-pink-600">{Number(balances.memeReflections).toLocaleString('en-us', reward_fractions)} ETH</span></p>

            <hr className="my-4 border-purple-600" />

            <p>RCOON Balance: <span className="ml-2 text-pink-600">{Number(balances.rcoonBalance).toLocaleString('en-us', balance_fractions)}</span></p>
            <p className="text-xs">Exit your RCOON position as we are launching a new token to replace it</p>

            <hr className="my-4 border-purple-600" />

            <p>DEGENWIF Balance: <span className="ml-2 text-pink-600">{Number(balances.degenWifBalance).toLocaleString('en-us', balance_fractions)}</span></p>
            <p>DEGENWIF Rewards: <span className="ml-2 text-pink-600">{Number(balances.degenWifReflections).toLocaleString('en-us', reward_fractions)} ETH</span></p>

          </>
        )}
      </div>
    </article>
  )
}

export default WalletInfo;