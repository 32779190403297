import React, { useRef, useState, useContext } from "react";
import logo from "../assets/logo.webp";

const Header = (props) => {
    return (
        <header className="top-0 z-10 py-4">
            <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                    <div className="flex-1 md:flex md:items-center md:gap-12">
                        <a className="block text-teal-600" href="/">
                            <span className="sr-only">Home</span>
                            <img src={logo} className="h-14" />
                        </a>
                    </div>
                    <div className="md:flex md:items-center md:gap-12">
                        <nav aria-label="Global" className="block">
                            <ul className="flex items-center gap-6 text-sm text-white">
                                <li>
                                    <a className="transition hover:text-gray-500/75" href="https://octostake.cryptocritters.meme">
                                        {" "}
                                        OctoStake{" "}
                                    </a>
                                </li>
                                <li>
                                    <a className="transition hover:text-gray-500/75" href="/tos">
                                        {" "}
                                        TOS{" "}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="transition hover:text-gray-500/75"
                                        target="_blank"
                                        href="https://zibars-organization.gitbook.io/cryptocritters/"
                                    >
                                        {" "}
                                        Litepaper{" "}
                                    </a>
                                </li>
                                <li>
                                    <a className="transition hover:text-gray-500/75" href="/calculator">
                                        {" "}
                                        Calculator{" "}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <div className="flex items-center gap-4">
                            <div className="sm:flex sm:gap-4 dark:text-black"></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
