import React, { useContext, useEffect, useState } from 'react';
import { ethers, JsonRpcProvider } from 'ethers'
import { PriceContext } from '../context/PriceContext'

export default function RewardsChecker () {
  const [inputAddress, setInputAddress] = useState('')
  const [showBalances, setShowBalances] = useState(false)
  const [balances, setBalances] = useState({
    bbcBalance: null,
    bbcReflections: null,

    memeBalance: null,
    memeReflections: null,

    rcoonBalance: null,

    degenWifBalance: null,
    degenWifReflections: null,

    dollarValue: null
  });

  const { plsPriceUsd } = useContext(PriceContext);
  const balance_fractions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  const reward_fractions = { minimumFractionDigits: 6, maximumFractionDigits: 6 }

  const bbc_abi = require('../assets/bbc.abi.json')
  const rewards_abi = require('../assets/dividend.token.abi.json')

  const rpcurl = 'https://base-rpc.publicnode.com'
  const provider = new JsonRpcProvider(rpcurl)

  const bbc_address = process.env.REACT_APP_BBC_ADDRESS
  const meme_address = process.env.REACT_APP_MEME_ADDRESS
  const rcoon_address = process.env.REACT_APP_RCOON_ADDRESS
  const degenwif_address = process.env.REACT_APP_DEGENWIF_ADDRESS

  const bbc = new ethers.Contract(bbc_address, bbc_abi, provider)
  const meme = new ethers.Contract(meme_address, bbc_abi, provider)
  const rcoon = new ethers.Contract(rcoon_address, bbc_abi, provider)
  const degenwif = new ethers.Contract(degenwif_address, bbc_abi, provider)

  const bbc_rewards_address = process.env.REACT_APP_BBC_REWARDS
  const meme_rewards_address = process.env.REACT_APP_MEME_REWARDS
  const degenwif_rewards_address = process.env.REACT_APP_DEGENWIF_REWARDS

  const bbc_reward = new ethers.Contract(bbc_rewards_address, rewards_abi, provider)
  const meme_reward = new ethers.Contract(meme_rewards_address, rewards_abi, provider)
  const degenwif_reward = new ethers.Contract(degenwif_rewards_address, rewards_abi, provider)

  async function fetchBalancesAndReflections(lookup) {
    try {
      setBalances({
        bbcBalance: ethers.formatEther(await bbc.balanceOf(lookup)),
        bbcReflections: ethers.formatEther(await bbc_reward.withdrawnDividendOf(lookup)),
        memeBalance: ethers.formatEther(await meme.balanceOf(lookup)),
        memeReflections: ethers.formatEther(await meme_reward.withdrawnDividendOf(lookup)),
        rcoonBalance: ethers.formatEther(await rcoon.balanceOf(lookup)),
        degenWifBalance: ethers.formatEther(await degenwif.balanceOf(lookup)),
        degenWifReflections: ethers.formatEther(await degenwif_reward.withdrawnDividendOf(lookup)),
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleAddressChange = (event) => {
    setInputAddress(event.target.value)
  }

  const handleAddressSubmit = async (event) => {
    event.preventDefault()

    if (/^0x[a-fA-F0-9]{40}$/.test(inputAddress)) {
      await fetchBalancesAndReflections(inputAddress)
      setShowBalances(true)
    } else {
      alert("Please enter a valid Ethereum address.")
    }
  }

  const handleCheckAnother = () => {
    setShowBalances(false);
    setInputAddress('');
  }

  return (
    <article className="w-full mx-auto hover:animate-background rounded-xl bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 p-0.5 shadow-xl transition hover:bg-[length:400%_400%] hover:shadow-sm hover:[animation-duration:_4s]">
      <div className="h-full rounded-[10px] bg-white p-4 sm:p-6">
        {showBalances ? (
          <div>
            <h4 className="mb-4 font-semibold text-gray-600 dark:text-gray-300">
              Critter Rewards
            </h4>

            <p>BBC Balance: <span className="ml-2 text-pink-600">{Number(balances.bbcBalance).toLocaleString('en-us', balance_fractions)}</span></p>
            <p>BBC Rewards: <span className="ml-2 text-pink-600">{Number(balances.bbcReflections).toLocaleString('en-us', reward_fractions)} ETH</span></p>

            <hr className="my-4 border-purple-600" />

            <p>MEME Balance: <span className="ml-2 text-pink-600">{Number(balances.memeBalance).toLocaleString('en-us', balance_fractions)}</span></p>
            <p>MEME Rewards: <span className="ml-2 text-pink-600">{Number(balances.memeReflections).toLocaleString('en-us', reward_fractions)} ETH</span></p>

            <hr className="my-4 border-purple-600" />

            <p>RCOON Balance: <span className="ml-2 text-pink-600">{Number(balances.rcoonBalance).toLocaleString('en-us', balance_fractions)}</span></p>
            <p className="text-xs">Exit your RCOON position as we are launching a new token to replace it</p>

            <hr className="my-4 border-purple-600" />

            <p>DEGENWIF Balance: <span className="ml-2 text-pink-600">{Number(balances.degenWifBalance).toLocaleString('en-us', balance_fractions)}</span></p>
            <p>DEGENWIF Rewards: <span className="ml-2 text-pink-600">{Number(balances.degenWifReflections).toLocaleString('en-us', reward_fractions)} ETH</span></p>

            <a onClick={handleCheckAnother} className="mt-4 cursor-pointer inline-block rounded bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center">
              <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">Check Another Address</span>
            </a>
          </div>
        ) : (
          <>
            <h3 className="mt-0.5 text-lg font-medium text-gray-900">
              Check Critters Rewards
            </h3>

            <div className="mt-4 flex flex-wrap gap-1">
              <input
                id="manualAddressInput"
                className="block w-full text-sm h-10 p-2 my-2 rounded border border-gray-300 mt-1"
                type="text"
                placeholder="Enter Wallet Address"
                value={inputAddress}
                onChange={handleAddressChange}
              />
              
              <a onClick={handleAddressSubmit} className="cursor-pointer inline-block rounded bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center">
                <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">Check Rewards</span>
              </a>
            </div>
          </>
        )}
        

      </div>
    </article>
  )
}
