
import React, { useRef, useState, useContext } from 'react'

import banner from '../assets/banner.webp'

export default function Home() {
  return (
    <>
      <div className="container mx-auto my-10 md:my-16 px-0 md:px-16 md:text-justify">
        <img src={banner} /> 
      </div>

      <div className="container mx-auto my-10 md:my-16 px-12 md:px-16 md:text-justify">
        <h1 className="text-2xl font-bold mb-4 text-white">Welcome to the Ultimate Meme Adventure</h1>
        <p>Critter Coin Chaos: Hop into a wild ride where critters & digital assets mingle, clash, and party on the Base blockchain. It's like a digital zoo, but with memes and crypto.</p><br />
        <p>AI Art Antics: Watch or participate as the journey unfolds when AI turns into our meme-making machine, spitting out critter art, wacky social posts, and soon some Telegram banter. It’s the fun side of the tech revolution.  No robot apocalypse — yet. </p><br />
        <p>No Secrets Squad: Led by a team who’s not hiding in the shadows, we’re all about keeping it real and making decisions together. Speak up, and let’s steer this crazy ship as a community of critters!</p>
      </div>

      <div className="container w-full mx-auto my-10 md:my-16 px-12 md:px-16 text-white text-sm lg:text-lg text-center">
      <p>$BBC 0x249D39ABFbd0Ce37BAC4A74a190aa438E6a58DA6</p>
      <p>$MEME 0x29e482b53640b945C42DaA0FE1b0e3112DDa1748</p>
      <p>$RCOON 0xdc253329defc629b4f4944ee0f82703221e09fdf - (inactive)</p>
      <p>$DegenWif 0x02E48B2c2834F31629715C8501643b07D3140F60</p>
      </div>
    </>
  )
}
