import React from 'react';
import { Route, Routes } from 'react-router-dom'

import Header from './components/header'
import Footer from './components/footer'

import banner from './assets/banner.webp'
import bbc from './assets/bbc.webp'
import meme from './assets/meme.webp'

import Home from './pages/home'
import Page404 from './pages/404'
import Calculator from './pages/calculator'
import Tos from './pages/tos'

function App() {
  return (
    <div className="w-screen min-h-screen bg-gradient-to-tr from-green-700 via-indigo-400 to-green-500">
      <Header></Header>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tos" element={<Tos />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="*" element={<Page404 />} />
      </Routes>

      <Footer></Footer>
    </div>
  );
}

export default App;
