import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'

export const PriceContext = React.createContext()

export const PriceProvider = ({ children }) => {
  const [bbcPriceUsd, setBbcPriceUsd] = useState(0)
  const [memePriceUsd, setMemePriceUsd] = useState(0)
  const [rcoonPriceUsd, setRcoonPriceUsd] = useState(0)
  const [degenwifPriceUsd, setDegenPriceUsd] = useState(0)
  const [wethPriceUsd, setWethPriceUsd] = useState(0)

  useEffect(() => {
    async function fetchPrice() {
      try {
        const response = await fetch('https://api.dexscreener.com/latest/dex/pairs/base/0xbc5d37d12d0ab65c67e6b30707d0e65d5c57ed2a')
        const data = await response.json();
        const priceUsd = data.pairs[0].priceUsd;
  
        if (priceUsd) {
          setBbcPriceUsd(parseFloat(priceUsd));
        }
  
        const meme_response = await fetch('https://api.dexscreener.com/latest/dex/pairs/base/0x19B054f6b15813c1769d7b1f7e4239F2e6f84F0C')
        const meme_data = await meme_response.json();
        const meme_priceUsd = meme_data.pairs[0].priceUsd;
  
        if (meme_priceUsd) {
          setMemePriceUsd(parseFloat(meme_priceUsd));
        }
  
        const degenwif_response = await fetch('https://api.dexscreener.com/latest/dex/pairs/base/0x72aab0dad5250daed32ce02d2b4f7b7d6dbfcc86')
        const degenwif_data = await degenwif_response.json();
        const degenwif_priceUsd = degenwif_data.pairs[0].priceUsd;
  
        if (degenwif_priceUsd) {
          setDegenPriceUsd(parseFloat(degenwif_priceUsd));
        }

        // price is not showing up
        // const rcoon_response = await fetch('https://api.dexscreener.com/latest/dex/pairs/base/0xf1E02A3c03eB2ebD845A3dDb017B0401EE486F46')
        // const rcoon_data = await rcoon_response.json();
        // const rcoon_priceUsd = rcoon_data.pairs[0].priceUsd;
  
        // if (rcoon_priceUsd) {
        //   setRcoonPriceUsd(parseFloat(rcoon_priceUsd));
        // }
  
        const weth_response = await fetch('https://api.dexscreener.com/latest/dex/pairs/base/0xd0b53d9277642d899df5c87a3966a349a798f224')
        const weth_data = await weth_response.json();
        const weth_priceUsd = weth_data.pairs[0].priceUsd;
  
        if (weth_priceUsd) {
          setWethPriceUsd(parseFloat(weth_priceUsd));
        }
      } catch (e) {
        console.log(e)
      }
    }

    fetchPrice();
  }, [])

  return (
    <PriceContext.Provider value={{ 
      bbcPriceUsd,
      memePriceUsd,
      rcoonPriceUsd,
      degenwifPriceUsd,
      wethPriceUsd,
    }}>
      {children}
    </PriceContext.Provider>
  )
}
