import React, { useRef, useState, useContext } from 'react'
import xlogo from '../assets/x.logo.webp'


const Footer = (props) => {
  return (
    <section className="mt-20">
      <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
        <nav className="flex flex-wrap justify-center -mx-5 -my-2 text-white">
          <div className="px-5 py-2">
            <a href="/tos" className="text-base leading-6 hover:text-gray-900"> TOS </a>
          </div>
          <div className="px-5 py-2">
            <a href="https://zibars-organization.gitbook.io/cryptocritters/" target="_blank" className="text-base leading-6 hover:text-gray-900"> Litepaper </a>
          </div>
          <div className="px-5 py-2">
            <a href="/calculator" className="text-base leading-6 hover:text-gray-900"> Calculator </a>
          </div>
        </nav>
        <div className="flex justify-center mt-4 space-x-6">
          <a target="_blank" href="https://t.me/CryptoCrittersSOL" className="text-indigo-600 hover:text-purple-400">
            <span className="sr-only">Telegram</span>
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 0.5c-8.563 0-15.5 6.938-15.5 15.5s6.938 15.5 15.5 15.5c8.563 0 15.5-6.938 15.5-15.5s-6.938-15.5-15.5-15.5zM23.613 11.119l-2.544 11.988c-0.188 0.85-0.694 1.056-1.4 0.656l-3.875-2.856-1.869 1.8c-0.206 0.206-0.381 0.381-0.781 0.381l0.275-3.944 7.181-6.488c0.313-0.275-0.069-0.431-0.482-0.156l-8.875 5.587-3.825-1.194c-0.831-0.262-0.85-0.831 0.175-1.231l14.944-5.763c0.694-0.25 1.3 0.169 1.075 1.219z"/>
            </svg>
          </a>
          <a target="_blank" href="https://twitter.com/dCryptoCritters" className="text-purple-600 hover:text-purple-400">
            <span className="sr-only">X</span>
            <img src={xlogo} className="w-6 h-6" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Footer