import React, { useContext, useEffect, useState } from 'react'
import { ethers, JsonRpcProvider } from 'ethers'
import { PriceContext } from '../context/PriceContext'


const StatsPanel = () => {
  const {
    bbcPriceUsd,
    memePriceUsd,
    rcoonPriceUsd,
    degenwifPriceUsd,
    wethPriceUsd
  } = useContext(PriceContext)

  const [bbcTotalSupply, setBbcTotalSupply] = useState(0)
  const [memeTotalSupply, setMemeTotalSupply] = useState(0)
  const [rcoonTotalSupply, setRcoonTotalSupply] = useState(0)
  const [degenwifTotalSupply, setDegenwifTotalSupply] = useState(0)
  
  const bbc_address = process.env.REACT_APP_BBC_ADDRESS
  const meme_address = process.env.REACT_APP_MEME_ADDRESS
  const rcoon_address = process.env.REACT_APP_RCOON_ADDRESS
  const degenwif_address = process.env.REACT_APP_DEGENWIF_ADDRESS

  const bbc_abi = require('../assets/bbc.abi.json')
  const rpcurl = 'https://base-rpc.publicnode.com'
  const provider = new JsonRpcProvider(rpcurl)

  const bbc = new ethers.Contract(bbc_address, bbc_abi, provider)
  const meme = new ethers.Contract(meme_address, bbc_abi, provider)
  const rcoon = new ethers.Contract(rcoon_address, bbc_abi, provider)
  const degenwif = new ethers.Contract(degenwif_address, bbc_abi, provider)


  useEffect(() => {
    async function fetchStats() {
      const bbc_total_supply = await bbc.totalSupply()
      const meme_total_supply = await meme.totalSupply()
      const rcoon_total_supply = await rcoon.totalSupply()
      const degenwif_total_supply = await degenwif.totalSupply()

      setBbcTotalSupply(ethers.formatUnits(bbc_total_supply))
      setMemeTotalSupply(ethers.formatUnits(meme_total_supply))
      setRcoonTotalSupply(ethers.formatUnits(rcoon_total_supply))
      setDegenwifTotalSupply(ethers.formatUnits(degenwif_total_supply))
    }

    fetchStats()
  }, [])


  return (
    <div className="text-white bg-purple-300 bg-gradient-to-tr from-green-700 to-green-300 rounded shadow-xs col-start-1 col-end-7 p-4">
      <h4 className="mb-4 font-semibold">
        Stats
      </h4>

      <div className="mt-4 flex flex-wrap gap-1">
        <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-xs text-purple-600">
          $BBC ${bbcPriceUsd}
        </span>
        <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-xs text-purple-600">
          $MEME ${memePriceUsd}
        </span>
        <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-xs text-purple-600">
          $DEGENWIF ${degenwifPriceUsd}
        </span>

        <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-xs text-purple-600 ml-4">
          $RCOON deprecated
        </span>
      </div>

      <div className="mt-4 flex flex-wrap gap-1">
        <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-xs text-purple-600">
          $ETH ${wethPriceUsd}
        </span>
      </div>

      <div className="mt-6 mb-2">$BBC current supply: <span className="text-purple-800">{Number(bbcTotalSupply).toLocaleString('en-us')} </span>
      </div>

      <div className="mb-2">$MEME current supply: <span className="text-purple-800">{Number(memeTotalSupply).toLocaleString('en-us')} </span>
      </div>

      <div className="mb-2">$DEGENWIF current supply: <span className="text-purple-800">{Number(degenwifTotalSupply).toLocaleString('en-us')} </span>
      </div>

      <div className="mb-2">$RCOON current supply: <span className="text-purple-800">{Number(rcoonTotalSupply).toLocaleString('en-us')} </span>
      </div>

    </div>
  );
}

export default StatsPanel
