import React from 'react';

import StatsPanel from '../components/StatsPanel'
import RewardsChecker from '../components/RewardsChecker'
import WalletInfo from '../components/WalletInfo'

export default function Calculator() {
  return (
    <div className="container mx-auto my-10 md:my-16 px-0 md:px-16 md:text-justify">
      <div className="bg-gray-50 flex flex-col justify-around relative overflow-hidden sm:py-12 rounded-lg">
        <div className="w-full grid gap-6 mb-8 px-6 pt-6 md:pt-0">
          <article className="hidden mx-auto hover:animate-background rounded-xl bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 p-0.5 shadow-xl transition hover:bg-[length:400%_400%] hover:shadow-sm hover:[animation-duration:_4s]">
            <div className="rounded-[10px] bg-white p-4 !pt-20 sm:p-6">
                <a href="#">
                  <h3 className="mt-0.5 text-lg font-medium text-gray-900">
                    Crypto Critters Reflections
                  </h3>
                </a>

                <div className="mt-4 flex flex-wrap gap-1">
                  <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-xs text-purple-600">
                    $BBC
                  </span>
                  <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-xs text-purple-600">
                    $MEME
                  </span>
                  <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-xs text-purple-600">
                    $RCOON
                  </span>
                </div>
              </div>
          </article>

          <StatsPanel />
        </div>

        <div className="w-full grid gap-6 mb-8 lg:grid-cols-2 px-6">
          <WalletInfo />
          <RewardsChecker />
        </div>
      </div>
    </div>
  )
}
