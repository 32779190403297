import React from 'react'

export default function Page404 () {
  return (
    <>
      <div className="container mx-auto my-10 md:my-16 px-12 md:px-16 md:text-justify">
        <h1 className="text-2xl font-bold mb-2 text-white">Terms of Service</h1>
        <p>Please carefully read the following Terms of Service ("Terms") before accessing or using the CryptoCritters website ("Site" or "Platform") at https://cryptocritters.meme/. By accessing or using the Site, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not access or use the Site.</p>
        
        <h2 className="text-lg font-bold mt-6 mb-2 text-white">Acceptance of Terms:</h2>
        <p>By accessing or using the CryptoCritters website, you acknowledge and agree to comply with these Terms and all applicable laws and regulations.</p>

        <h2 className="text-lg font-bold mt-6 mb-2 text-white">Changes to Terms:</h2>
        <p>CryptoCritters reserves the right to modify, revise, or update these Terms at any time without prior notice. Your continued use of the Site after any changes signifies your acceptance of the revised Terms. It is your responsibility to review these Terms regularly.</p>

        <h2 className="text-lg font-bold mt-6 mb-2 text-white">Age Restriction:</h2>
        <p>The CryptoCritters website is intended for individuals who are at least 18 years old. By accessing or using the Site, you confirm that you are of legal age. If you are under 18, you may only use the Site under the supervision of a parent or legal guardian.</p>

        <h2 className="text-lg font-bold mt-6 mb-2 text-white">Prohibited Conduct:</h2>
        <p>You agree not to engage in any conduct that may disrupt, interfere with, or harm the functionality of the Site. Prohibited conduct includes but is not limited to unauthorized access, data scraping, or any activity that violates applicable laws or regulations.</p>

        <h2 className="text-lg font-bold mt-6 mb-2 text-white">Intellectual Property:</h2>
        <p>All content on the CryptoCritters website, including but not limited to text, graphics, logos, images, audio clips, and software, is the property of CryptoCritters and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works without the express written consent of CryptoCritters.</p>        

        <h2 className="text-lg font-bold mt-6 mb-2 text-white">Disclaimer of Warranties:</h2>
        <p>The CryptoCritters website is provided "as is" without any warranties, either expressed or implied. We do not warrant the accuracy, completeness, or reliability of any content on the Site.</p>


        <h2 className="text-lg font-bold mt-6 mb-2 text-white">Limitation of Liability:</h2>
        <p>CryptoCritters, its affiliates, and their respective directors, officers, employees, agents, and representatives shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of the Site.</p>

        <h2 className="text-lg font-bold mt-6 mb-2 text-white">Contact Information:</h2>
        <p>For any questions or concerns regarding these Terms of Service, please contact us at <a className="hover:text-white" href="https://twitter.com/dCryptoCritters" target="_blank">https://twitter.com/dCryptoCritters</a>. <br/>
        By accessing or using the CryptoCritters website, you agree to these Terms of Service. If you do not agree to these Terms, please refrain from using the Site.</p>
      </div>
      
    </>
  )
}
